<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <delete-campaign
      v-if="showDelete"
      :title="'Delete campaign?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
    <delete-banner
      v-if="showBannerDelete"
      :title="'Delete this banner from current campaign?'"
      @closed="showBannerDelete = false"
      @confirmed="deleteBannerConfirmed()"
    />
    <v-alert
      v-if="!permissionCheck('read-user-campaign')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-row>
        <v-col>
          <v-toolbar
            flat
            color="#ECEFF1"
          >
            <v-toolbar-title class="my-toolbar-style">
              User Campaign List
            </v-toolbar-title>
          </v-toolbar>
          <v-data-table
            :headers="userCampaignTable.headers"
            :items="userCampaignList"
            :single-expand="singleExpand"
            :search="search"
            :expanded.sync="expanded"
            item-key="id"
            show-expand
            class="elevation-1 clickable"
            mobile-breakpoint="100"
          >
            <template v-slot:item="{ item, expand, isExpanded }">
              <tr
                class="clickable"
                @click="getCampaignContent(item.id)"
              >
                <td>{{ item.name }}</td>
                <td>{{ item.start | convertStringToLocalDatetime }}</td>
                <td>
                  <font v-if="item.end">
                    {{ item.end | convertStringToLocalDatetime }}
                  </font>
                  <font v-else>
                    No End date
                  </font>
                </td>
                <td>
                  <font
                    :color="getColor(item.active)"
                    class="font-my-style"
                  >
                    {{ item.active | statusUpdate }}
                  </font>
                </td>
                <td>
                  {{ item.updated_at | diffHumans }}
                </td>
                <td>
                  <v-btn
                    class="mt-n2"
                    elevation="1"
                    fab
                    color="#37474F"
                    x-small
                    icon
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </td>
                <td>
                  <v-col>
                    <v-btn
                      class="mt-n2"
                      elevation="1"
                      fab
                      x-small
                      icon
                      color="#C62828"
                      @click.stop
                      @click="deleteUserCampaign(item.id, item.banners[0].boards, item.global)"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </v-col>
                </td>
                <td>
                  <v-btn
                    icon
                    small
                    @click.stop
                    @click="expand(!isExpanded)"
                  >
                    <v-icon
                      v-if="!isExpanded"
                      @click="getBoardDetails(item.banners[0])"
                    >
                      mdi-arrow-down-drop-circle
                    </v-icon>
                    <v-icon v-if="isExpanded">
                      mdi-arrow-up-drop-circle
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td
                v-if="permissionCheck('read-campaign')"
                :colspan="headers.length"
              >
                <v-row>
                  <v-col
                    v-if="showAdvancedSchedule(item.schedules)"
                    cols="12"
                    lg="4"
                  >
                    <v-card>
                      <v-toolbar
                        color="#37474F"
                        dark
                      >
                        <v-toolbar-title>Advanced Schedule</v-toolbar-title>
                      </v-toolbar>
                      <v-list>
                        <v-list-item-group>
                          <template v-for="(schedule, index) in item.schedules">
                            <v-list-item :key="'item'+ index">
                              <template>
                                <v-list-item-content class="ml-1">
                                  <v-list-item-title>
                                    <strong>
                                      Start: {{ schedule.start | twelveHour }} End: {{ schedule.end | twelveHour }}
                                    </strong>
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    <strong>
                                      Active Days: {{ schedule.active_days | arrangePretty }}
                                    </strong>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-list-item>
                            <v-divider
                              v-if="index < userCampaignList.length - 1"
                              :key="index"
                            />
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    :lg="isLargeScreen"
                  >
                    <v-card>
                      <v-toolbar
                        color="#37474F"
                        dark
                      >
                        <v-toolbar-title>
                          File Preview
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-card-text class="text-body-1 text-center">
                        <v-row class="my-row-style">
                          <v-col
                            v-for="banner in item.banners"
                            :key="banner.id"
                            :cols="filePreviewDispay"
                            class="my-card-size"
                          >
                            <v-badge
                              right
                              overlap
                            >
                              <v-icon
                                slot="badge"
                                small
                                @click.native="deleteFile(item.id, banner.id, item.banners.length, banner.boards, item.global)"
                              >
                                mdi-trash-can-outline
                              </v-icon>
                              <div v-if="isContentImgOrGif(banner.content.content_type)">
                                <v-img
                                  width="75"
                                  height="75"
                                  contain
                                  class="my-media-file"
                                  :src="getFilePath(banner.content.file_path)"
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="#FF3700"
                                      />
                                    </v-row>
                                  </template>
                                </v-img>
                              </div>
                              <div v-else>
                                <video
                                  width="75"
                                  height="75"
                                  class="my-media-file"
                                  controls
                                  contain
                                >
                                  <source
                                    :src="getFilePath(banner.content.file_path)"
                                    type="video/mp4"
                                  >
                                </video>
                              </div>
                            </v-badge>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="4"
                  >
                    <v-card>
                      <v-toolbar
                        color="#37474F"
                        dark
                      >
                        <v-toolbar-title>Device Details</v-toolbar-title>
                      </v-toolbar>
                      <v-list>
                        <v-list-item-group>
                          <template v-for="(device, index) in boardDetails ">
                            <v-list-item :key="'item'+ index">
                              <template>
                                <v-list-item-content class="ml-1">
                                  <v-list-item-title>
                                    <strong>
                                      Device Id: {{ device.external_id }}
                                    </strong>
                                  </v-list-item-title>
                                  <br>
                                  <v-list-item-subtitle>
                                    <strong>
                                      Device Location: {{ device.location }}
                                    </strong>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-list-item>
                            <v-divider
                              v-if="index < boardDetails.length - 1"
                              :key="index"
                            />
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <div class="py-3" />
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-container>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import { cdnUrl } from 'src/app-globals';
  import Constants from 'src/constants';
  import ModuleHelper from 'src/helpers/module-helper';
  import global from 'src/mixins/global';

  export default {
    name: 'UserCampaignList',
    components: {
      'centre-spinner': spinner,
      'delete-campaign': DeleteDialog,
      'delete-banner': DeleteDialog,
    },
    filters: {
      diffHumans (val) {
        return moment(val).fromNow();
      },
      arrangePretty (val) {
        return val.join(', ');
      },
      twelveHour (val) {
        return moment(val, 'HH:mm').format('hh:mm a');
      },
      convertStringToLocalDatetime (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      statusUpdate (active) {
        if (active === true) return 'Active*';
        else return 'Inactive';
      },
    },
    mixins: [global],
    data () {
      return {
        loading: false,
        search: '',
        showDelete: false,
        showBannerDelete: false,
        deleteId: '',
        deleteBannerId: '',
        deleteCampaignId: '',
        userCampaignTable: {
          headers: [
            { text: 'Name', align: 'start', value: 'name' },
            { text: 'Start', value: 'start' },
            { text: 'End', value: 'end' },
            { text: 'Status', value: 'active' },
            { text: 'Last Updated', value: 'updated_at' },
            { text: 'Edit', value: 'edit' },
            { text: 'Delete', value: 'delete' },
            { text: 'More', value: 'data-table-expand' },
          ],
        },
        expanded: [],
        singleExpand: true,
        helper: new ModuleHelper(),
        boardExternalIds: [],
        boardDetails: [],
        isGlobal: false,
      };
    },
    computed: {
      userCampaignList () {
        const list = this.$store.getters['campaigns/getUserCampaignList'];
        const sortedList = list.sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        return sortedList;
      },
      digiBoards () {
        return this.$store.getters['digiboards/getDigiBoards'];
      },
      isLargeScreen () {
        if (this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return 4;
        } else {
          return 12;
        }
      },
      filePreviewDispay () {
        if (this.isLargeScreen === 4) {
          return 4;
        } else {
          return 2;
        }
      },
    },
    async mounted () {
      this.permissionCheck('read-user-campaign') && await this.getUserCampaignList();
      this.$store.dispatch('campaigns/clearBannerFile');
      this.$store.dispatch('campaigns/clearCampaignFile');
      this.$store.dispatch('schedule/clearAdvancedScheduleForEdit');
      this.$store.dispatch('campaigns/clearCampaignContent');
      (this.permissionCheck('read-user-campaign') && this.permissionCheck('read-campaign')) && this.fetchDigiBoards();
    },
    methods: {
      showAdvancedSchedule (schedule) {
        if (this.helper.isSubModuleExist(Constants.MODULE_CAMPAIGN, Constants.SUB_MODULE_ADVANCED_SCHEDULING) && schedule.length > 0) {
          return true;
        }
        return false;
      },
      async getUserCampaignList () {
        this.loading = true;
        await this.$store.dispatch('campaigns/fetchUserCampaignList');
        this.loading = false;
      },
      fetchDigiBoards () {
        this.$store.dispatch('digiboards/fetchDigiBoards');
      },
      getColor (active) {
        if (active === false) return '#C62828';
        else return '#2E7D32';
      },
      deleteUserCampaign (userCampaignId, boardExternalIds, isGlobal) {
        if (this.permissionCheck('remove-campaign') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showDelete = true;
        this.deleteId = userCampaignId;
        this.boardExternalIds = boardExternalIds;
        this.isGlobal = isGlobal;
      },
      deleteConfirmed () {
        this.$store.dispatch('publish/addSelectedBoardsForPublish', this.boardExternalIds);
        this.$store.dispatch('campaigns/deleteCampaign', this.deleteId)
          .then(response => {
            this.$store.dispatch('alert/onAlert', {
              message: 'Campaign deleted.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.getUserCampaignList();
            if (this.isGlobal === true) {
             this.getGlobalCampaignList();
            }
          }).finally(
            this.showDelete = false,
            this.deleteId = null,
          );
      },
      getFilePath (fileName) {
        return `${cdnUrl}/${fileName}`;
      },
      deleteFile (campaignId, bannerId, bannerLength, boardExternalIds, isGlobal) {
        if (this.permissionCheck('remove-campaign-by-bannerId') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        if (bannerLength === 1) {
          this.$store.dispatch('alert/onAlert', {
            message: 'At least one content is required to run the campaign.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.showBannerDelete = true;
        this.deleteBannerId = bannerId;
        this.deleteCampaignId = campaignId;
        this.boardExternalIds = boardExternalIds;
        this.isGlobal = isGlobal;
      },
      deleteBannerConfirmed () {
        this.$store.dispatch('publish/addSelectedBoardsForPublish', this.boardExternalIds);
        this.$store.dispatch('campaigns/removeBannerFromCampaign', {
          campaignId: this.deleteCampaignId,
          bannerId: this.deleteBannerId,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Banner removed from campaign.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          if (this.isGlobal === true) {
            this.getGlobalCampaignList();
          }
          this.getUserCampaignList();
        }).finally(
          this.showBannerDelete = false,
          this.campaignId = null,
          this.deleteBannerId = null,
        );
      },
      getCampaignContent (campaignId) {
        if (this.permissionCheck('edit-campaign') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.$router.push(`/campaigns/${campaignId}`);
      },
      isContentImgOrGif (contentType) {
        if (contentType === 'image' || contentType === 'gif') {
          return true;
        }
        return false;
      },
      getBoardDetails (boardDetails) {
        if (this.permissionCheck('read-campaign') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        const filteredBoards = this.digiBoards.filter(item => boardDetails.boards.includes(item.external_id));
        this.boardDetails = filteredBoards;
      },
       getGlobalCampaignList () {
        this.$store.dispatch('campaigns/fetchGlobalCampaignList');
        this.isGlobal = false;
      },
    },
  };
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.font-my-style {
  font-weight: bold;
}
.v-data-table {
  background-color: #ECEFF1;
}
.my-toolbar-style {
  color: #37474F;
  font-weight: bold;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.my-media-file {
  background-color: rgb(236, 239, 241);
}
.my-card-size {
  height: 120px;
  padding-left: 5px;
  padding-right: 5px;
}
.my-row-style {
  margin-top: 5px;
}
</style>
