<template>
  <div>
    <v-dialog
      v-model="showModal"
      max-width="800"
    >
      <v-card
        shaped
        color="#ECEFF1"
      >
        <br>
        <h2 class="title-style">
          <v-icon class="icon-style">
            mdi-television
          </v-icon> Select Device
        </h2>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="mt-2"
            color="#FF3700"
            @click="apply()"
          >
            Apply
          </v-btn>
        </v-card-actions>
        <base-tree-view-without-actions
          :value="treeData"
          class="my-tree-view-style"
        >
          <span slot-scope="{node, path, tree}">
            <input
              id="myCheck"
              type="checkbox"
              :checked="node.$checked"
              @change="onCheckBoxChange(node, tree, path)"
            >
            {{ node.text }}
          </span>
        </base-tree-view-without-actions>
        <br>
        <centre-spinner
          :loading="loading"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import { walkTreeData } from 'he-tree-vue';
  import { getDefaultStructure, addMissingBoards } from 'src/helpers/digi-board-structure-helper';
  import moment from 'moment-timezone';
  import global from 'src/mixins/global';

  export default {
    name: 'DigiBoardListForCalendar',
    components: {
      'centre-spinner': spinner,
    },
    mixins: [global],
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        selectedDigiBoards: [],
        treeData: [],
        loading: false,
      };
    },
    computed: {
      showModal: {
        get: function () {
            return this.value;
        },
        set: function (value) {
            this.$emit('input', value);
        },
      },
      savedStructure () {
        return this.$store.getters['grouping/getSavedStructure'];
      },
      digiBoards () {
        return this.$store.getters['digiboards/getDigiBoards'];
      },
    },
    async mounted () {
      this.loading = true;
      this.permissionCheck('read-group') && await this.fetchDigiBoardSavedStructure();
      this.permissionCheck('read-board-list') && await this.fetchDigiBoards();
      this.showBoardTreeList();
      this.loading = false;
    },
    methods: {
      async fetchDigiBoardSavedStructure () {
        await this.$store.dispatch('grouping/fetchSavedStructure');
      },
      async fetchDigiBoards () {
        await this.$store.dispatch('digiboards/fetchDigiBoards');
      },
      showBoardTreeList () {
        if (Object.keys(this.savedStructure).length <= 0) {
          const digiBoards = this.digiBoards;
          this.treeData = getDefaultStructure(digiBoards);
          return;
        }
        const treeData = this.savedStructure.group;
        const digiBoards = this.digiBoards;
        this.treeData = addMissingBoards(treeData, digiBoards);
      },
      onCheckBoxChange (node, tree, path) {
        tree.toggleCheck(node, path);
        const selectedNodes = [];
        tree.walkTreeData((node) => {
          node.$checked && selectedNodes.push(node);
        });
        this.selectedDigiBoards = selectedNodes;
      },
      convertToGmt (date) {
        return moment.utc(date).format();
      },
      async apply () {
        this.loading = true;
        const finalResult = [];
        walkTreeData(this.treeData, (node) => {
          if (node.$checked && node.id !== undefined) {
            finalResult.push(node.id);
          }
        });
        const thisWeekFirstDay = moment().startOf('week');
        const thisWeekLastDay = moment().endOf('week');
        await this.$store.dispatch('campaigns/fetchWeeklyCampaignList', {
          params: {
            from: this.convertToGmt(thisWeekFirstDay),
            to: this.convertToGmt(thisWeekLastDay),
            timezone: moment.tz.guess(),
            'boardIds[]': finalResult,
          },
        }).then(response => {
          this.$emit('display-calendar');
          this.showModal = false;
        });
        this.loading = false;
      },
    },
  };
</script>
<style scoped>
.my-tree-view-style {
  margin-left: 30px;
}
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
</style>
