<template>
  <div>
    <digi-board-list-for-calendar
      v-model="showDigiBoardList"
      @display-calendar="getEvents()"
    />
    <v-dialog
      v-model="showDialog"
      max-width="500"
    >
      <v-card
        class="my-card-style"
        color="#ECEFF1"
      >
        <v-toolbar
          color="#37474F"
          dark
        >
          <v-toolbar-title>
            Campaign Content
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div>
            <p class="my-p-style">
              Campaign Name:- <font class="font-my-style">
                {{ selectedContent.name }}
              </font>
            </p>
          </div>
          <div>
            <div>
              <p class="my-p-style">
                Displayed From:- <font class="font-my-style">
                  {{ selectedContent.start | convertStringToLocalDatetime }}
                </font>
              </p>
            </div>
            <div>
              <p
                v-if="selectedContent.end !== null"
                class="my-p-style"
              >
                Displayed To:- <font class="font-my-style">
                  {{ selectedContent.end | convertStringToLocalDatetime }}
                </font>
              </p>
              <p
                v-else
                class="my-p-style"
              >
                Displayed To:- <font class="font-my-style">
                  No End Date
                </font>
              </p>
            </div>
          </div>
          <v-row>
            <v-col
              v-for="banner in campaignContent.banners"
              :key="banner.id"
              :cols="columnToDisplay"
            >
              <div
                v-if="isContentImgOrGif(banner.content.content_type)"
              >
                <v-img
                  width="75"
                  height="75"
                  contain
                  :src="getFilePath(banner.content.file_path)"
                  @click="popUpImage(banner.content.file_path)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#FF3700"
                      />
                    </v-row>
                  </template>
                </v-img>
              </div>
              <div v-else>
                <video
                  width="75"
                  height="75"
                  contain
                  controls
                >
                  <source
                    :src="getFilePath(banner.content.file_path)"
                    type="video/mp4"
                  >
                </video>
              </div>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              @click="showDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="edit()"
            >
              Edit
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <br>
    <v-alert
      v-if="!permissionCheck('read-campaign-schedule')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar-title
            v-if="$refs.calendar && isScreenSmall === true"
            class=" my-toolbar-style text-center"
          >
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-toolbar
            flat
            color="#CFD8DC"
          >
            <v-btn
              outlined
              class="mr-4"
              color="#37474F"
              :small="isScreenSmall"
              @click="setToday"
            >
              Today
            </v-btn>
            <v-toolbar-title
              v-if="$refs.calendar && isScreenSmall === false"
              class="toolbar-header-style"
            >
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer />
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2"
                  v-bind="attrs"
                  dark
                  color="#37474F"
                  outlined
                  :small="isScreenSmall"
                  v-on="on"
                  @click="showDigiBoardListModal()"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-television
                  </v-icon>
                  Devices
                </v-btn>
              </template>
              <span>Select Device</span>
            </v-tooltip>
            <v-menu
              bottom
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="#37474F"
                  v-bind="attrs"
                  :small="isScreenSmall"
                  v-on="on"
                >
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            :now="today"
            :value="today"
            :events="events"
            :type="type"
            color="#FF3700"
            interval-height="24"
            event-more
            class="my-calendar-style"
            @click:more="viewDay"
            @click:date="viewDay"
            @click:event="showEvent"
          />
        </v-sheet>
      </v-col>
    </v-row>
    <centre-spinner
      :loading="loading"
    />
    <image-preview
      v-if="showPreview"
      :url="previewUrl"
      @closed="showPreview = false"
    />
  </div>
</template>
<script>

import moment from 'moment-timezone';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import { cdnUrl } from 'src/app-globals';
import ImagePreview from '../component/ImagePreview';
import DigiBoardListForCalendar from './DigiBoardListForCalendar.vue';
import global from 'src/mixins/global';
import Constants from 'src/constants';

  export default {
    name: 'CampaignCalendarView',
    components: {
      'centre-spinner': spinner,
      'image-preview': ImagePreview,
      'digi-board-list-for-calendar': DigiBoardListForCalendar,
    },
    filters: {
      convertStringToLocalDatetime (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },
    mixins: [global],
    data () {
      return {
        today: moment().format('YYYY-MM-DD hh:mm'),
        events: [],
        colors: { green: 'green darken-3', red: 'red darken-3' },
        selectedContent: {},
        loading: false,
        focus: '',
        showDialog: false,
        showPreview: false,
        previewUrl: '',
        type: 'week',
        typeToLabel: {
          week: 'Week',
          day: 'Day',
        },
        showDigiBoardList: false,
      };
    },
    computed: {
      weeklyCampaignList () {
        return this.$store.getters['campaigns/getWeeklyCampaignList'];
      },
      campaignContent () {
        return this.$store.getters['campaigns/getCampaignContent'];
      },
      isScreenSmall () {
         if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
          return true;
        } else {
          return false;
        }
      },
      columnToDisplay () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return 3;
        } else {
          return 4;
        }
      },
    },
    watch: {
      showDialog (value) {
        if (value === false) {
          this.$store.dispatch('campaigns/clearCampaignContent');
        }
      },
    },
    async mounted () {
      this.loading = true;
      this.permissionCheck('read-campaign-schedule') && await this.getWeeklyCampaignList();
      this.loading = false;
    },
    methods: {
      convertToGmt (date) {
        return moment.utc(date).format();
      },
      async getWeeklyCampaignList () {
        const thisWeekFirstDay = moment().startOf('week');
        const thisWeekLastDay = moment().endOf('week');
        await this.$store.dispatch('campaigns/fetchWeeklyCampaignList', {
          params: {
            from: this.convertToGmt(thisWeekFirstDay),
            to: this.convertToGmt(thisWeekLastDay),
            timezone: moment.tz.guess(),
          },
        }).then(response => {
          this.getEvents();
        });
      },
      setToday () {
        this.focus = '';
      },
      viewDay ({ date }) {
        this.focus = date;
        this.type = 'day';
      },
      async showEvent ({ event }) {
        if (this.permissionCheck('read-campaign') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.selectedContent = event;
        await this.$store.dispatch('campaigns/fetchCampaignContent', {
          id: this.selectedContent.id,
        });
        this.showDialog = true;
      },
      convertToLocalDatetime (Datetime) {
        return moment(Datetime).local().format('YYYY-MM-DD HH:mm:ss');
      },
      getEvents () {
        this.events = [];
        const campaignName = [];
        const campaignStartDate = [];
        const campaignEndDate = [];
        const campaignId = [];

        for (const campaign of this.weeklyCampaignList) {
          campaignName.push(campaign.name);
          campaignStartDate.push(campaign.start);
          campaignEndDate.push(campaign.end !== null
          ? campaign.end
          : this.convertToLocalDatetime(moment().add(1, 'M')));
          campaignId.push(campaign.campaign_id);
        }
        const currentDateTime = moment().local().format('YYYY-MM-DD HH:mm:ss');
        for (var i = 0; i < this.weeklyCampaignList.length; i++) {
          if (currentDateTime >= campaignStartDate[i] && currentDateTime <= campaignEndDate[i]) {
            this.events.push({
              name: campaignName[i],
              start: campaignStartDate[i],
              end: campaignEndDate[i],
              id: campaignId[i],
              color: this.colors.green,
            });
          } else {
            this.events.push({
              name: campaignName[i],
              start: campaignStartDate[i],
              end: campaignEndDate[i],
              id: campaignId[i],
              color: this.colors.red,
            });
          }
        }
      },
      edit () {
        if (this.permissionCheck('edit-campaign') === false) {
          this.showDialog = false;
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.$router.push(`/campaigns/${this.selectedContent.id}`);
      },
      isContentImgOrGif (contentType) {
        if (contentType === 'image' || contentType === 'gif') {
          return true;
        }
        return false;
      },
       getFilePath (fileName) {
        return `${cdnUrl}/${fileName}`;
      },
      popUpImage (fileName) {
        this.previewUrl = this.getFilePath(fileName);
        this.showPreview = true;
      },
      showDigiBoardListModal () {
        if (this.permissionCheck('read-campaign-schedule') === true && (this.permissionCheck('read-group') === true || this.permissionCheck('read-board-list') === true)) {
          this.showDigiBoardList = true;
          return;
        }
        this.$store.dispatch('alert/onAlert', {
          message: 'You are not authorized to perform this action. Please contact your administrator.',
          type: Constants.ALERT_TYPE_INFO,
        });
      },
    },
  };
</script>
<style scoped>
.my-calendar-style {
  background-color: #CFD8DC !important;
  font-weight: bold;
}
.font-my-style {
  font-weight: bold;
  font-size: 15px;
  color: #37474F;
}
.my-p-style {
  font-size: 16px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.my-card-style {
  border-radius: 15px;
}
.toolbar-header-style {
  color: #37474F;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.my-toolbar-style {
  color: #37474F;
  background-color: #CFD8DC;
}
</style>
