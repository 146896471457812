<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-bullhorn
      </v-icon> Campaigns List
    </h2>
    <v-col
      class="text-right"
    >
      <v-btn
        v-if="permissionCheck('create-campaign')"
        color="#FF3700"
        @click="createCampaign()"
      >
        Create Campaign
      </v-btn>
    </v-col>
    <v-tabs
      v-model="tab"
      color="#FF3700"
      background-color="#ECEFF1"
    >
      <v-tab
        v-for="item in items"
        :key="item.tab"
      >
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      class="my-tabs-background-style"
      touchless
    >
      <v-tab-item
        v-for="item in items"
        :key="item.tab"
      >
        <v-card-text>
          <component :is="item.content" />
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
  import UserCampaignList from './UserCampaignList.vue';
  import GlobalCampaignList from './GlobalCampaignList.vue';
  import CampaignCalendarView from './CampaignCalendarView.vue';
  import global from 'src/mixins/global';
  import ModuleHelper from 'src/helpers/module-helper';
  import constants from 'src/constants';

  export default {
    name: 'CampaignList',

    components: {
      UserCampaignList,
      GlobalCampaignList,
      CampaignCalendarView,
    },
    mixins: [global],
    data: () => ({
      tab: null,
      items: [
        { tab: 'User Campaigns', content: UserCampaignList },
        { tab: 'Global Campaigns', content: GlobalCampaignList },
        { tab: 'Campaign Calendar', content: CampaignCalendarView },
      ],
      helper: new ModuleHelper(),
    }),
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (vm.isPlanExpired === true || vm.helper.isModuleExist(constants.MODULE_CAMPAIGN) === false) {
          vm.$router.push({ name: 'Dashboard' });
        } else {
          next();
        }
      });
    },
    computed: {
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      isPlanExpired () {
        if (Object.keys(this.organizationDetails).length > 0 && this.organizationDetails.subscription.subscription_status === 'expired') {
          return true;
        }
        return false;
      },
    },
    watch: {
      isPlanExpired (val) {
        if (val === true) {
          this.$router.push({ name: 'Logout' });
        }
      },
    },
    methods: {
      createCampaign () {
        this.$router.push({ name: 'Create Campaign' });
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-tabs-background-style {
    background-color: #ECEFF1 !important;
}
</style>
